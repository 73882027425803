var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.Results)?_c('div',[_c('el-table',{attrs:{"data":_vm.Results,"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"label":"Test"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.other_status &&
              scope.row.other_status.adaptive_practice_id
          )?_c('el-tag',{staticClass:"mr-2",attrs:{"type":"warning"}},[_c('b',[_vm._v("Adaptive")])]):_vm._e(),(!_vm.isAdmin && scope.row.is_finished === 0)?_c('span',[_vm._v(" "+_vm._s(scope.row.exam.title)+" ")]):_c('router-link',{attrs:{"to":{
            name: 'SATResult',
            query: {
              practiceType: _vm.type
            },
            params: { id: scope.row.id }
          }}},[(scope.row.is_finished)?_c('b',[_vm._v(_vm._s(scope.row.exam.title))]):_c('span',[_vm._v(_vm._s(scope.row.exam.title))])])]}}],null,false,1300572722)}),(_vm.isAdmin)?_c('el-table-column',{attrs:{"label":"Student"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'AdminViewUser',
            params: {
              id: scope.row.user.id
            }
          }}},[(scope.row.is_finished)?_c('b',[_vm._v(" "+_vm._s(_vm.user.displayName( scope.row.user.first_name, scope.row.user.last_name ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.user.displayName( scope.row.user.first_name, scope.row.user.last_name ))+" ")])])]}}],null,false,4250122856)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Score","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.is_finished)?_c('b',{staticClass:"text-success text-bold"},[_vm._v(" "+_vm._s(scope.row.total_score)+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.total_score)+" ")])]}}],null,false,2847294245)}),(false)?_c('el-table-column',{attrs:{"label":"Correct/Total","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.is_finished)?_c('b',[_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(scope.row.correct_count)+" ")]),_vm._v("/"+_vm._s(scope.row.exam.exam_questions_count)+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.correct_count)+"/"+_vm._s(scope.row.exam.exam_questions_count)+" ")])]}}],null,false,3933495471)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Status","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.is_finished === 0 ? 'info' : 'success',"disable-transitions":""}},[(scope.row.is_finished === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("courses.Incomplete"))+" ")]):_c('b',[_vm._v(_vm._s(_vm.$t("courses.completed")))])])]}}],null,false,1069767999)}),_c('el-table-column',{attrs:{"label":"Actions","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!_vm.isAdmin && scope.row.is_finished === 0)?_c('div',[_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('courses.Resume'),"placement":"top"}},[_c('router-link',{attrs:{"to":{
                  name: 'SATTest',
                  params: _vm.typeNow(_vm.type, scope.row),
                  query: {
                    user_exam_id: scope.row.id
                  }
                }}},[_c('el-button',{staticStyle:{"width":"50px"},attrs:{"type":"warning","size":"small"}},[_c('i',{staticClass:"fa fa-play"})])],1)],1)],1)]):_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"View","placement":"top"}},[_c('router-link',{attrs:{"to":{
                name: 'SATResult',
                query: {
                  practiceType: _vm.type
                },
                params: { id: scope.row.id }
              }}},[_c('el-button',{staticStyle:{"width":"50px"},attrs:{"type":"success","size":"small"}},[_c('i',{staticClass:"fa fa-eye"})])],1)],1)],1)]}}],null,false,3361217314)}),_c('el-table-column',{attrs:{"label":"Date","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.moment(scope.row.updated_at).format('YYYY-MM-DD HH:mm'),"placement":"top"}},[_c('span',[_vm._v(_vm._s(_vm.instant.formatGoogleTime(scope.row.updated_at)))])])]}}],null,false,4158815413)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }