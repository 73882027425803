<template>
  <div v-if="Results">
    <el-table :data="Results" :row-class-name="tableRowClassName">
      <el-table-column label="Test">
        <template slot-scope="scope">
          <el-tag
            class="mr-2"
            type="warning"
            v-if="
              scope.row.other_status &&
                scope.row.other_status.adaptive_practice_id
            "
          >
            <b>Adaptive</b>
          </el-tag>
          <span v-if="!isAdmin && scope.row.is_finished === 0">
            {{ scope.row.exam.title }}
          </span>
          <router-link
            v-else
            :to="{
              name: 'SATResult',
              query: {
                practiceType: type
              },
              params: { id: scope.row.id }
            }"
          >
            <b v-if="scope.row.is_finished">{{ scope.row.exam.title }}</b>
            <span v-else>{{ scope.row.exam.title }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Student" v-if="isAdmin">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'AdminViewUser',
              params: {
                id: scope.row.user.id
              }
            }"
          >
            <b v-if="scope.row.is_finished">
              {{
                user.displayName(
                  scope.row.user.first_name,
                  scope.row.user.last_name
                )
              }}
            </b>
            <span v-else>
              {{
                user.displayName(
                  scope.row.user.first_name,
                  scope.row.user.last_name
                )
              }}
            </span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Score" width="100">
        <template slot-scope="scope">
          <b v-if="scope.row.is_finished" class="text-success text-bold">
            {{ scope.row.total_score }}
          </b>
          <span v-else>
            {{ scope.row.total_score }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Correct/Total" width="150" v-if="false">
        <template slot-scope="scope">
          <b v-if="scope.row.is_finished">
            <span class="text-success"> {{ scope.row.correct_count }} </span>/{{
              scope.row.exam.exam_questions_count
            }}
          </b>
          <span v-else>
            {{ scope.row.correct_count }}/{{
              scope.row.exam.exam_questions_count
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Status" width="120px">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_finished === 0 ? 'info' : 'success'"
            disable-transitions
          >
            <span v-if="scope.row.is_finished === 0">
              {{ $t("courses.Incomplete") }}
            </span>
            <b v-else>{{ $t("courses.completed") }}</b>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="80">
        <template slot-scope="scope">
          <div v-if="!isAdmin && scope.row.is_finished === 0">
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('courses.Resume')"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'SATTest',
                    params: typeNow(type, scope.row),
                    query: {
                      user_exam_id: scope.row.id
                    }
                  }"
                >
                  <el-button type="warning" size="small" style="width: 50px;">
                    <i class="fa fa-play"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
            </div>
          </div>
          <div v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="View"
              placement="top"
            >
              <router-link
                :to="{
                  name: 'SATResult',
                  query: {
                    practiceType: type
                  },
                  params: { id: scope.row.id }
                }"
              >
                <el-button type="success" size="small" style="width: 50px;">
                  <i class="fa fa-eye"></i>
                </el-button>
              </router-link>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Date" width="100">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="moment(scope.row.updated_at).format('YYYY-MM-DD HH:mm')"
            placement="top"
          >
            <span>{{ instant.formatGoogleTime(scope.row.updated_at) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { instant, user } from "@ivy-way/material";
import moment from "moment";
export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["type", "Results", "role"],
  data() {
    return {
      moment
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    isAdmin() {
      return this.role === "admin";
    }
  },
  watch: {},

  mounted() {},

  methods: {
    typeNow(type, row) {
      if (type === "drill") {
        return {
          practiceType: "singlePractice",
          id: row.exam.test_id
        };
      } else {
        if (row.other_status.adaptive_practice_id) {
          return {
            practiceType: "adaptivePractices",
            id: row.other_status.adaptive_practice_id
          };
        } else {
          return {
            practiceType: type,
            id: row.exam.test_id
          };
        }
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.is_finished === 1) {
        return "success-row";
      }
      return "incomplete-row";
    }
  }
};
</script>

<style scoped>
::v-deep .el-table .warning-row {
  background: #fdf5e6;
}
::v-deep .el-table .success-row {
  background: #f0f9eb;
}
/* ::v-deep .el-table .incomplete-row {
  background: #fefefe;
} */
</style>
